/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, BoxProps, Box } from "theme-ui";
import moneybag from "../../images/moneybag.png";
import HeaderStat from "./HeaderStat";

export interface CurrentPoolHeaderStateProps extends BoxProps {
  prizePool: number | undefined;
}

const NON_BREAKING_SPACE = "\xa0";

const CurrentPoolHeaderStat = memo(function CurrentPoolHeaderStat({
  prizePool,
  ...boxProps
}: CurrentPoolHeaderStateProps): ReactElement {
  return (
    <Box {...boxProps}>
      <HeaderStat
        image={moneybag}
        title="Current Pool:"
        stat={
          prizePool != null
            ? `${prizePool.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ETH`
            : NON_BREAKING_SPACE
        }
      />
    </Box>
  );
});
export default CurrentPoolHeaderStat;
