/** @jsx jsx */
import { memo, ReactElement, useState, useEffect, ComponentProps } from "react";
import { jsx, Text } from "theme-ui";
import { delay } from "../../util/async";

export interface CountdownProps extends ComponentProps<typeof Text> {
  expireTime: number;
}

const Countdown = memo(function Countdown({
  expireTime,
  ...boxProps
}: CountdownProps): ReactElement {
  const [timeRemaining, setTimeRemaining] = useState(
    Math.max(expireTime - Date.now()),
  );

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      while (!isCancelled) {
        const newTimeRemaining = Math.max(0, expireTime - Date.now());
        setTimeRemaining(newTimeRemaining);
        if (newTimeRemaining <= 0) {
          return;
        }
        const timeToNextUpdate = 1000 - newTimeRemaining;
        await delay(timeToNextUpdate);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [expireTime]);

  const totalSeconds = (timeRemaining / 1000) | 0;
  const seconds = zeroPad(totalSeconds % 60);
  const totalMinutes = (totalSeconds / 60) | 0;
  const minutes = zeroPad(totalMinutes % 60);
  const hours = zeroPad((totalMinutes / 60) | 0);

  return (
    <Text {...boxProps}>
      {hours}:{minutes}:{seconds}
    </Text>
  );
});
export default Countdown;

function zeroPad(n: number): string {
  return String(n).padStart(2, "0");
}
