import { SxStyleProp } from "theme-ui";

export const titleSx: SxStyleProp = {
  fontSize: 6,
  fontWeight: "bold",
};

export const buttonSx: SxStyleProp = {
  display: "inline-block",
  px: 5,
  py: 1,
  borderRadius: "8px",
  fontFamily: "'Saira', sans-serif",
  fontSize: 3,
  fontWeight: "bold",
  backgroundColor: "orange.4",
  color: "white",
  cursor: "pointer",
  textDecoration: "none",
  transition: "transform ease-in-out 100ms",
  transform: "initial",

  ":disabled": {
    color: "gray.6",
    backgroundColor: "gray.7",
    cursor: "not-allowed",
  },
  ":hover:not(:disabled)": {
    backgroundColor: "orange.5",
    transform: "scale(1.02)",

    ":active": { backgroundColor: "orange.6" },
  },
};

export const containerSx: SxStyleProp = {
  width: "100%",
  mx: "auto",
  maxWidth: [null, "540px", "720px", "960px", "1140px"],
};
