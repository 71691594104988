/** @jsx jsx */
import { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { jsx } from "theme-ui";

const DEFAULT_DESCRIPTION =
  'Beat your friends\' crypto portfolios in this decentralized game of "skill"!';

export interface HtmlHeadProps {
  title: string;
  description?: string;
}

const HtmlHead = ({
  title,
  description = DEFAULT_DESCRIPTION,
}: HtmlHeadProps): ReactElement => (
  <Helmet
    htmlAttributes={{ lang: "en" }}
    title={title}
    titleTemplate="Dapp Queens- %s"
    meta={[
      { name: "description", content: description },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:type", content: "website" },
    ]}
    link={[
      {
        href:
          "https://fonts.googleapis.com/css2?family=Saira:wght@400;500;700&display=swap",
        rel: "stylesheet",
      },
    ]}
  />
);
export default HtmlHead;
