/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, BoxProps, Box } from "theme-ui";
import stopwatch from "../../images/stopwatch.png";
import Countdown from "../common/Countdown";
import HeaderStat from "./HeaderStat";

export interface TimeLeftHeaderStat extends BoxProps {
  submissionDeadline: number | undefined;
  competitionEndTime: number | undefined;
}

const NON_BREAKING_SPACE = "\xa0";

const TimeLeftHeaderStat = memo(function TimeLeftHeaderStat({
  submissionDeadline,
  competitionEndTime,
  ...boxProps
}: TimeLeftHeaderStat): ReactElement {
  let title = "Time left";
  let expireTime: number | undefined;

  if (submissionDeadline && Date.now() < submissionDeadline) {
    title = "Time left to join:";
    expireTime = submissionDeadline;
  } else if (competitionEndTime) {
    title = "Time left in competition:";
    expireTime = competitionEndTime;
  }

  return (
    <Box {...boxProps}>
      <HeaderStat
        image={stopwatch}
        title={title}
        stat={
          expireTime != null ? (
            <Countdown expireTime={expireTime} />
          ) : (
            NON_BREAKING_SPACE
          )
        }
      />
    </Box>
  );
});
export default TimeLeftHeaderStat;
