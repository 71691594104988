/** @jsx jsx */
import { memo, ReactElement, ReactNode } from "react";
import { jsx, BoxProps, Flex, Box } from "theme-ui";

export interface HeaderStatProps extends BoxProps {
  image: string;
  title: string;
  stat: ReactNode;
}
const HeaderStat = memo(function HeaderStat({
  image,
  title,
  stat,
  ...boxProps
}: HeaderStatProps): ReactElement {
  return (
    <Box {...boxProps}>
      <Flex sx={{ alignItems: "center" }}>
        <img src={image} height="29px" sx={{ mr: 2 }} />
        <div>
          <div sx={{ fontSize: "12px", mb: 0, pb: 0 }}>{title}</div>
          <div sx={{ fontSize: "16px", pt: 0, mt: -1 }}>{stat}</div>
        </div>
      </Flex>
    </Box>
  );
});
export default HeaderStat;
