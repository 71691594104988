/** @jsx jsx */
import { Global } from "@emotion/core";
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";

const GlobalStyles = memo(function GlobalStyles(): ReactElement {
  return (
    <Global
      styles={(theme) => ({
        a: {
          color: "inherit",
          textDecoration: "none",
          ":hover": { textDecoration: "underline" },
          "&.active": { color: theme.colors.orange[4] },
        },
      })}
    />
  );
});
export default GlobalStyles;
