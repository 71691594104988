import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  ActionCreator,
  ActionCreatorsMapObject,
  bindActionCreators,
} from "redux";

export function useBindActionCreators<
  T extends ActionCreator<any> | ActionCreatorsMapObject<any>
>(actionCreators: T): T {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(actionCreators as any, dispatch), []);
}
