/** @jsx jsx */
import { memo, ReactElement, ReactNode } from "react";
import { jsx, Box } from "theme-ui";
import backgroundImage from "../images/background.png";
import GlobalStyles from "./GlobalStyles";
import HtmlHead, { HtmlHeadProps } from "./HtmlHead";
import Header from "./header/Header";
import { containerSx } from "./style";

export interface LayoutProps extends HtmlHeadProps {
  children: ReactNode;
}

const Layout = memo(function Layout({
  children,
  ...headProps
}: LayoutProps): ReactElement {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <HtmlHead {...headProps} />
      <GlobalStyles />
      <Header />
      <Box sx={containerSx}>{children}</Box>
    </Box>
  );
});
export default Layout;
