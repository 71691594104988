/** @jsx jsx */
import { Link } from "gatsby";
import { memo, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { jsx, BoxProps, Flex, Box } from "theme-ui";
import logo from "../../images/logo.png";
import { Loadable } from "../../redux/loadable";
import {
  fetchSubmissionDeadlineAction,
  fetchCompetitionEndTimeAction,
  RootState,
  fetchContestInfoAction,
  getPrizePool,
} from "../../redux/root";
import { containerSx } from "../style";
import { useBindActionCreators } from "../util/hooks";
import CurrentPoolHeaderStat from "./CurrentPoolHeaderStat";
import TimeLeftHeaderStat from "./TimeLeftHeaderStat";

export type HeaderProps = BoxProps;

const Header = memo(function Header({
  ...boxProps
}: HeaderProps): ReactElement {
  const submissionDeadline = useSelector(
    (state: RootState) => state.submissionDeadline,
  );
  const competitionEndTime = useSelector(
    (state: RootState) => state.competitionEndTime,
  );
  const contestInfo = useSelector((state: RootState) => state.contestInfo);
  const {
    fetchContestInfo,
    fetchSubmissionDeadline,
    fetchCompetitionEndTime,
  } = useBindActionCreators({
    fetchContestInfo: fetchContestInfoAction,
    fetchSubmissionDeadline: fetchSubmissionDeadlineAction,
    fetchCompetitionEndTime: fetchCompetitionEndTimeAction,
  });

  useEffect(() => {
    fetchContestInfo();
    fetchSubmissionDeadline();
    fetchCompetitionEndTime();
  }, []);

  const competitionIsLoaded =
    contestInfo.hasValue &&
    submissionDeadline.hasValue &&
    competitionEndTime.hasValue;

  return (
    <Box sx={containerSx}>
      <Flex
        {...boxProps}
        py={3}
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 2,
          fontWeight: "bold",
        }}
      >
        <Link to="/">
          <img src={logo} height="29px" />
        </Link>
        <span>
          <Link to="/rankings" activeClassName="active">
            Rankings
          </Link>
          <Link sx={{ ml: 4 }} to="/how-to-play" activeClassName="active">
            How to play
          </Link>
          <Link sx={{ ml: 4 }} to="/how-it-works" activeClassName="active">
            How it works
          </Link>
        </span>
        <Flex
          sx={{
            justifyContent: "flex-end",
            minWidth: "350px",
            transition: "opacity ease-out 500ms",
            opacity: competitionIsLoaded ? 1 : 0,
          }}
        >
          <CurrentPoolHeaderStat
            sx={{ mr: 3 }}
            prizePool={contestInfo.value && getPrizePool(contestInfo.value)}
          />
          <TimeLeftHeaderStat
            submissionDeadline={submissionDeadline.value}
            competitionEndTime={competitionEndTime.value}
          />
        </Flex>
      </Flex>
    </Box>
  );
});
export default Header;
